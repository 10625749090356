import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  ActivityType,
  ActivityTypeWithAllowance,
  Country,
  Customer,
  Dealer,
} from "../../models/Core";
import { ISectionStatus } from "../../models/ISectionStatus";
import {
  ActivityListViewModel,
  // DeviationAuditStatus,
  AllowancesReportViewModel,
  CalendarEvent,
  CustomEvent,
  CustomerDaysProductivityViewModel,
  DaysListViewModel,
  DeviationQuestion,
  DeviationSection,
  DeviationSubSection,
  DeviationTopic,
  Event,
  EventActivityTypes,
  EventForecast,
  EventNote,
  EventResult,
  EventToilAccrual,
  PersonTotalDays,
  PlanningProductivityViewModel,
  ProjectActivityTotalDays,
  ProjectPersonTotalDays,
  ProjectTotalDays,
  QuarterlyDaysSummaryViewModel,
  QuarterlySummaryViewModel,
  SubEvent,
  TeamListViewModel,
  ToDo,
} from "../../models/Planning";
import { Project, ProjectRequest } from "../../models/Project";
import { MonthlyCountViewModel } from "../../models/Resourcing";
import { IAutocompleteOption } from "../../models/UI";
import {
  AsyncThunkFactoryDelete,
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { RootState } from "../store";

export interface IPlanningSliceState {
  //Resourcing
  personTotalDays: PersonTotalDays[] | PersonTotalDays;
  personMonthlyCount: MonthlyCountViewModel[];
  projectMonthlyCount: MonthlyCountViewModel[];
  activityMonthlyCount: MonthlyCountViewModel[];
  //
  projectsTotalDays: ProjectTotalDays[];
  projectActivityPlanningOverview: ProjectActivityTotalDays[];
  projectPersonPlanningOverview: ProjectPersonTotalDays[];
  personAllowanceReport: AllowancesReportViewModel[];
  activityList: ActivityListViewModel;
  teamList: TeamListViewModel;
  planningProductivity: PlanningProductivityViewModel[];
  customerDaysProductivity: CustomerDaysProductivityViewModel[];
  quarterlyActivitySummary: QuarterlySummaryViewModel[];
  quarterlyDaysSummary: QuarterlyDaysSummaryViewModel;
  daysList: DaysListViewModel;
  activityTypes: ActivityTypeWithAllowance[];
  projects: Project[];
  activityStatusProjects: Project[];
  projectRequests: ProjectRequest[];
  customers: Customer[];
  reportCustomers: Customer[];
  reportActivityTypes: ActivityType[];
  reportCountries: Country[];
  dealers: Dealer[];
  calendarEvents: CalendarEvent[];
  eventsToApprove: Event[];
  subEvents: SubEvent[];
  events: Event[];
  event: Event;
  eventToil: EventToilAccrual;
  eventActivityTypes: EventActivityTypes;
  plannedDates: SubEvent[];
  todos: ToDo[];
  customEvent: CustomEvent;
  eventResults: EventResult;
  eventsForecasts: EventForecast[];
  completedDeviations: Event[];
  deviationSection: DeviationSection[];
  deviationSubSection: DeviationSubSection[];
  deviationTopic: DeviationTopic[];
  deviationQuestion: DeviationQuestion[];
  deviationResults: string | undefined;
  nextEventStartDate: string | undefined;
  sectionStatuses: {
    projects: ISectionStatus;
    dealers: ISectionStatus;
    personTotalDays: ISectionStatus;
    personAllowanceReport: ISectionStatus;
    activityList: ISectionStatus;
    teamList: ISectionStatus;
    planningProductivity: ISectionStatus;
    customerDaysProductivity: ISectionStatus;
    quarterlyActivitySummary: ISectionStatus;
    quarterlyDaysSummary: ISectionStatus;
    daysList: ISectionStatus;
    activityTypes: ISectionStatus;
    customers: ISectionStatus;
    reportCustomers: ISectionStatus;
    reportActivityTypes: ISectionStatus;
    reportCountries: ISectionStatus;
    eventsToApprove: ISectionStatus;
    subEvents: ISectionStatus;
    events: ISectionStatus;
    event: ISectionStatus;
    eventResults: ISectionStatus;
    eventsForecasts: ISectionStatus;
    completedDeviations: ISectionStatus;
    deviationSection: ISectionStatus;
    deviationSubSection: ISectionStatus;
    deviationTopic: ISectionStatus;
    deviationQuestion: ISectionStatus;
    deviationResults: ISectionStatus;
    nextEventStartDate: ISectionStatus;
    eventToil: ISectionStatus;
    eventActivityTypes: ISectionStatus;
    planningAllocations: ISectionStatus;
    plannedDates: ISectionStatus;
    projectsTotalDays: ISectionStatus;
    projectActivityStatus: ISectionStatus;
    projectActivityPlanningOverview: ISectionStatus;
    projectPersonPlanningOverview: ISectionStatus;
    eventNote: ISectionStatus;
    customEvent: ISectionStatus;
  };
}

const initialState: IPlanningSliceState = {
  //Resourcing
  personTotalDays: [],
  personMonthlyCount: [],
  projectMonthlyCount: [],
  activityMonthlyCount: [],
  //
  projectsTotalDays: [],
  projectActivityPlanningOverview: [],
  projectPersonPlanningOverview: [],
  personAllowanceReport: [],
  activityList: { activityEntries: [], customerTotals: [] },
  teamList: { personStringDates: [], styles: [] } as TeamListViewModel,
  planningProductivity: [],
  customerDaysProductivity: [],
  quarterlyActivitySummary: [],
  quarterlyDaysSummary: {
    unfilledDays: [],
    quarterlySummaries: [],
  } as QuarterlyDaysSummaryViewModel,
  daysList: { dayEntries: [], customerTotals: [] } as DaysListViewModel,
  activityTypes: [],
  projects: [],
  activityStatusProjects: [],
  projectRequests: [],
  customers: [],
  reportCustomers: [],
  reportActivityTypes: [],
  reportCountries: [],
  dealers: [],
  calendarEvents: [],
  eventsToApprove: [],
  subEvents: [],
  events: [],
  event: {} as Event,
  eventToil: {} as EventToilAccrual,
  eventActivityTypes: {} as EventActivityTypes,
  plannedDates: [],
  todos: [],
  customEvent: {} as CustomEvent,
  eventResults: {} as EventResult,
  eventsForecasts: [],
  completedDeviations: [],
  // projectActivityStatus: [],
  deviationSection: [],
  deviationSubSection: [],
  deviationTopic: [],
  deviationQuestion: [],
  deviationResults: undefined,
  nextEventStartDate: undefined,
  sectionStatuses: {
    projects: { status: "idle", error: undefined },
    projectsTotalDays: { status: "idle", error: undefined },
    personAllowanceReport: { status: "idle", error: undefined },
    activityList: { status: "idle", error: undefined },
    teamList: { status: "idle", error: undefined },
    planningProductivity: { status: "idle", error: undefined },
    customerDaysProductivity: { status: "idle", error: undefined },
    quarterlyActivitySummary: { status: "idle", error: undefined },
    quarterlyDaysSummary: { status: "idle", error: undefined },
    daysList: { status: "idle", error: undefined },
    eventsToApprove: { status: "idle", error: undefined },
    eventsForecasts: { status: "idle", error: undefined },
    completedDeviations: { status: "idle", error: undefined },
    deviationSection: { status: "idle", error: undefined },
    deviationSubSection: { status: "idle", error: undefined },
    deviationTopic: { status: "idle", error: undefined },
    deviationQuestion: { status: "idle", error: undefined },
    deviationResults: { status: "idle", error: undefined },
    nextEventStartDate: { status: "idle", error: undefined },
    subEvents: { status: "idle", error: undefined },
    events: { status: "idle", error: undefined },
    event: { status: "idle", error: undefined },
    projectActivityStatus: { status: "idle", error: undefined },
    eventResults: { status: "idle", error: undefined },
    eventToil: { status: "idle", error: undefined },
    eventActivityTypes: { status: "idle", error: undefined },
    dealers: { status: "idle", error: undefined },
    customers: { status: "idle", error: undefined },
    reportCustomers: { status: "idle", error: undefined },
    reportActivityTypes: { status: "idle", error: undefined },
    reportCountries: { status: "idle", error: undefined },
    activityTypes: { status: "idle", error: undefined },
    personTotalDays: { status: "idle", error: undefined },
    planningAllocations: { status: "idle", error: undefined },
    plannedDates: { status: "idle", error: undefined },
    projectActivityPlanningOverview: { status: "idle", error: undefined },
    projectPersonPlanningOverview: { status: "idle", error: undefined },
    eventNote: { status: "idle", error: undefined },
    customEvent: { status: "idle", error: undefined },
  },
};

export const getPlannedDates = AsyncThunkFactoryGet<SubEvent[]>(
  "planning/getPlannedDates",
  "planning/events/planneddates"
);

export const getToDos = AsyncThunkFactoryGet<ToDo[]>(
  "planning/getToDos",
  "planning/todos"
);

export const getProjectTotalDays = AsyncThunkFactoryGet<ProjectTotalDays[]>(
  "planning/getProjectTotalDays",
  "planning/reports"
);

export const getProjectActivityPlanningOverview = AsyncThunkFactoryGet<
  ProjectActivityTotalDays[]
>("planning/getProjectActivityPlanningOverview", "projects");

export const getProjectPersonPlanningOverview = AsyncThunkFactoryGet<
  ProjectPersonTotalDays[]
>("planning/getProjectPersonPlanningOverview", "projects");

//TODO: MOVE OUT INTO Resourcing SLICE?
export const getPersonTotalDays = AsyncThunkFactoryGet<PersonTotalDays[]>(
  "planning/getTotalDays",
  "Resourcing/reports"
);

export const getPersonMonthlyCount = AsyncThunkFactoryGet<
  MonthlyCountViewModel[]
>("planning/getPersonMonthlyCount", "Resourcing/reports/person");

export const getProjectMonthlyCount = AsyncThunkFactoryGet<
  MonthlyCountViewModel[]
>("planning/getProjectMonthlyCount", "Resourcing/reports/project");

export const getActivityMonthlyCount = AsyncThunkFactoryGet<
  MonthlyCountViewModel[]
>("planning/getActivityMonthlyCount", "Resourcing/reports/activity");
//

export const getActivityList = AsyncThunkFactoryGet<ActivityListViewModel>(
  "planning/getActivityList",
  "planning/reports/activitylist"
);

export const getTeamList = AsyncThunkFactoryGet<TeamListViewModel[]>(
  "planning/getTeamList",
  "planning/reports/teamList"
);

export const getPlanningProductivity = AsyncThunkFactoryGet<
  PlanningProductivityViewModel[]
>("planning/getPlanningProductivity", "planning/reports/planningProductivity");

export const getCustomerDaysProductivity = AsyncThunkFactoryGet<
  CustomerDaysProductivityViewModel[]
>(
  "planning/getCustomerDaysProductivity",
  "planning/reports/customerDaysProductivity"
);

export const getQuarterlyActivitySummary = AsyncThunkFactoryGet<
  QuarterlySummaryViewModel[]
>(
  "planning/getQuarterlyActivitySummary",
  "planning/reports/quarterlyActivitySummary"
);

export const getQuarterlyDaysSummary =
  AsyncThunkFactoryGet<QuarterlySummaryViewModel>(
    "planning/getQuarterlyDaysSummary",
    "planning/reports/quarterlyDaysSummary"
  );

export const getDaysList = AsyncThunkFactoryGet<DaysListViewModel>(
  "planning/getDaysList",
  "planning/reports/daysList"
);

export const getCalendar = AsyncThunkFactoryGet<CalendarEvent[]>(
  "planning/getCalendar",
  "planning/calendar"
);

export const getXAmountOfEvents = AsyncThunkFactoryGet<SubEvent[]>(
  "planning/getXAmountOfEvents",
  "planning/calendar"
);

export const getEventById = AsyncThunkFactoryGet<Event>(
  "planning/getEventById",
  "planning/events"
);

export const getEventSearch = AsyncThunkFactoryGet<Event>(
  "planning/getEventSearch",
  "planning/events/eventsearch"
);

export const getEventsForApproval = AsyncThunkFactoryGet<Event[]>(
  "planning/getEventsForApproval",
  "planning/events/authorizer"
);

export const getActivityTypesWithAllowances = AsyncThunkFactoryGet<
  ActivityTypeWithAllowance[]
>(
  "planning/getActivityTypesWithAllocations",
  "planning/planningallocations/project"
);
export const getEventsForecasts = AsyncThunkFactoryGet<EventForecast[]>(
  "planning/getEventsForecasts",
  "planning/eventsforecast"
);

export const getCompletedDeviations = AsyncThunkFactoryGet<Event[]>(
  "planning/getCompletedDeviations",
  "planning/eventResults/completedDeviations"
);

export const getDeviationSections = AsyncThunkFactoryGet<DeviationSection[]>(
  "planning/getDeviationSections",
  "planning/deviationquestions/sections"
);

export const getDeviationSubSections = AsyncThunkFactoryGet<
  DeviationSubSection[]
>(
  "planning/getDeviationSubSections",
  "planning/deviationquestions/subsections"
);

export const getDeviationTopics = AsyncThunkFactoryGet<DeviationTopic[]>(
  "planning/getDeviationTopics",
  "planning/deviationquestions/topics"
);

export const getDeviationQuestions = AsyncThunkFactoryGet<DeviationQuestion[]>(
  "planning/getDeviationQuestions",
  "planning/deviationquestions/questions"
);

export const putEvent = AsyncThunkFactoryPut(
  "planning/putEvent",
  "planning/events",
  "Successfully updated event",
  "Failed to update event"
);

export const putEventDeviation = AsyncThunkFactoryPut(
  "planning/putEventDeviation",
  "planning/eventResults/putDeviationQuestions",
  "Successfully updated Event Deviation",
  "Failed to update Event Deviation"
);

export const putActivityStatus = AsyncThunkFactoryPut(
  "planning/putActivityStatus",
  "planning/eventResults",
  "Successfully updated activity result",
  "Failed to update activity result"
);

export const putEventMilestone = AsyncThunkFactoryPut(
  "planning/putEventMilestone",
  "planning/events/milestone",
  "Successfully updated milestone",
  "Failed to update milestone"
);

export const putEventApproval = AsyncThunkFactoryPut(
  "planning/putEventApproval",
  "planning/events/approve",
  "Successfully updated event approval status",
  "Failed to update event approval status"
);

export const getProjectsForPlanning = AsyncThunkFactoryGet<Project[]>(
  "planning/getProjectsForPlanning",
  "planning/calendar"
);
export const getCountriesForPlanning = AsyncThunkFactoryGet<Country[]>(
  "planning/getCountries",
  "planning/calendar"
);

export const getAcquiredDeviation = AsyncThunkFactoryGet<string>(
  "planning/getAcquiredDeviations",
  "planning/eventResults/getAcquiredDeviations"
);

export const getNextEventStartDate = AsyncThunkFactoryGet<string>(
  "planning/getNextEventStartDate",
  "planning/events/getNextEventDate"
  // nextEventStartDate
);

export const getCustomersForPlanning = AsyncThunkFactoryGet<Customer[]>(
  "planning/getCustomers",
  "planning/calendar"
);

export const getProjectsForActivityStatus = AsyncThunkFactoryGet<Project[]>(
  "planning/getProjectsForActivityStatus",
  "planning/activitystatusview/selectprojects"
);

export const getActivityTypes = AsyncThunkFactoryGet<ActivityType[]>(
  "planning/getActivityTypes",
  "planning/calendar"
);

export const getDealersForPlanning = AsyncThunkFactoryGet<Dealer[]>(
  "planning/getDealers",
  "planning/calendar"
);

export const getEventActivityTypes = AsyncThunkFactoryGet<EventActivityTypes>(
  "planning/getEventTimeline",
  "planning/eventsTimeline"
);

export const putPlanningAllocations = AsyncThunkFactoryPut(
  "planning/putPlanningAllocations",
  "planning/planningallocations/byprojectid",
  "Successfully updated planning allocations",
  "Failed to update planning allocations"
);

export const deleteEvent = AsyncThunkFactoryDelete<Event>(
  "planning/deleteEvent",
  "planning/events",
  "Successfully deleted event",
  "Failed to delete event"
);

export const postEvent = AsyncThunkFactoryPost<Partial<Event>>(
  "planning/postEvent",
  "planning/events",
  "Successfully added event",
  "Failed to add event"
);

export const postNote = AsyncThunkFactoryPost<Partial<EventNote>>(
  "planning/postNote",
  "planning/eventnotes",
  "Successfully added event note",
  "Failed to add event note"
);

export const getPersonAllowancesReport = AsyncThunkFactoryGet<
  AllowancesReportViewModel[]
>(
  "planning/getPersonAllowanceReport",
  "planning/reports/specialAllowanceReport"
);

export const getReportCustomers = AsyncThunkFactoryGet<Customer[]>(
  "planning/getReportCustomers",
  "planning/reports/reportCustomers"
);

export const getReportActivityTypes = AsyncThunkFactoryGet<ActivityType[]>(
  "planning/getReportActivityTypes",
  "planning/reports/reportActivityTypes"
);

export const getReportCountries = AsyncThunkFactoryGet<Country[]>(
  "planning/getReportCountries",
  "planning/reports/reportCountries"
);

export const getCustomEvent = AsyncThunkFactoryGet<CustomEvent>(
  "planning/getCustomEvent",
  "planning/customevents"
);

export const postCustomEvent = AsyncThunkFactoryPost<Partial<CustomEvent>>(
  "planning/postCustomEvent",
  "planning/customevents",
  "Successfully added custom event",
  "Failed to add custom event"
);

export const putCustomEvent = AsyncThunkFactoryPut<CustomEvent>(
  "planning/putCustomEvent",
  "planning/customevents",
  "Successfully updated custom event",
  "Failed to update custom event"
);

export const deleteCustomEvent = AsyncThunkFactoryDelete<CustomEvent>(
  "planning/deleteCustomEvent",
  "planning/customevents",
  "Successfully deleted custom event",
  "Failed to delete custom event"
);

export const putEventToil = AsyncThunkFactoryPut<Partial<EventToilAccrual>>(
  "planning/putEventToil",
  "planning/events/addToil",
  "Successfully updated events toil",
  "Failed to update events toil"
);

export const deleteEventToil = AsyncThunkFactoryDelete<EventToilAccrual>(
  "planning/deleteEventToil",
  "planning/events/removeToil",
  "Successfully removed events toil",
  "Failed to remove events toil"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IPlanningSliceState>,
  f: any,
  field: keyof IPlanningSliceState,
  statusField: keyof IPlanningSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IPlanningSliceState>,
  f: any,
  field: keyof IPlanningSliceState,
  statusField: keyof IPlanningSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderDelete<T>(
  builder: ActionReducerMapBuilder<IPlanningSliceState>,
  f: any,
  field: keyof IPlanningSliceState,
  statusField: keyof IPlanningSliceState["sectionStatuses"],
  predicate?: (element: T) => boolean
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (action.payload.status === 204 && predicate) {
        (state[field] as any[]) = (state[field] as any[]).filter(
          predicate,
          action.meta.arg
        );
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IPlanningSliceState>,
  f: any,
  field: keyof IPlanningSliceState,
  statusField: keyof IPlanningSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const planningSlice = createSlice({
  name: "Planning",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "subEvents":
          state.sectionStatuses.subEvents.status = "idle";
          state.subEvents = initialState.subEvents;
          break;
        case "events":
          state.sectionStatuses.events.status = "idle";
          state.events = initialState.events;
          break;
        case "event":
          state.sectionStatuses.event.status = "idle";
          state.event = initialState.event;
          break;
        case "eventNote":
          state.sectionStatuses.eventNote.status = "idle";
          break;
        case "dealers":
          state.sectionStatuses.dealers.status = "idle";
          state.dealers = initialState.dealers;
          break;
        case "projects":
          state.sectionStatuses.projects.status = "idle";
          state.projects = initialState.projects;
          break;
        case "totalDays":
          state.sectionStatuses.personTotalDays.status = "idle";
          state.personTotalDays = initialState.personTotalDays;
          break;
        case "activityType":
          state.sectionStatuses.activityTypes.status = "idle";
          state.activityTypes = initialState.activityTypes;
          break;
        case "customers":
          state.sectionStatuses.customers.status = "idle";
          state.customers = initialState.customers;
          break;
        case "eventResults":
          state.sectionStatuses.eventResults.status = "idle";
          state.eventResults = initialState.eventResults;
          break;
        case "eventActivityTypes":
          state.sectionStatuses.eventResults.status = "idle";
          state.eventActivityTypes = initialState.eventActivityTypes;
          break;
        case "projectActivityStatus":
          state.sectionStatuses.projectActivityStatus.status = "idle";
          state.activityStatusProjects = initialState.activityStatusProjects;
          break;
        case "deviationResults":
          state.sectionStatuses.deviationResults.status = "idle";
          state.deviationResults = initialState.deviationResults;
          break;
        case "completedDeviations":
          state.sectionStatuses.completedDeviations.status = "idle";
          state.completedDeviations = initialState.completedDeviations;
          break;
      }
    },
    resetPlanning: (state) => {
      state.sectionStatuses.events.status = "idle";
      state.sectionStatuses.event.status = "idle";
      state.sectionStatuses.eventNote.status = "idle";
    },
    resetDialogDropdowns: (state) => {
      state.customers = [];
      state.activityTypes = [];
      state.dealers = [];
    },
    resetProject: (state) => {
      state.projects = [];
    },
    resetAllPlanningData: (state) => {
      state.event = {} as Event;
      state.customers = [];
      state.activityTypes = [];
      state.dealers = [];
    },
    clearAllowances: (state) => {
      state.personAllowanceReport = [];
    },
    clearActivityList: (state) => {
      state.activityList = { activityEntries: [], customerTotals: [] };
    },
    clearCalendar: (state) => {
      state.calendarEvents = [];
    },
  },
  extraReducers: (builder) => {
    ActionReducerBuilderGet(builder, getCalendar, "calendarEvents", "events");

    ActionReducerBuilderGet(
      builder,
      getXAmountOfEvents,
      "subEvents",
      "subEvents"
    );

    ActionReducerBuilderGet(
      builder,
      getPlannedDates,
      "plannedDates",
      "plannedDates"
    );

    //TODO: MOVE TO Resourcing SLICE

    ActionReducerBuilderGet(
      builder,
      getProjectActivityPlanningOverview,
      "projectActivityPlanningOverview",
      "projectActivityPlanningOverview"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectPersonPlanningOverview,
      "projectPersonPlanningOverview",
      "projectPersonPlanningOverview"
    );

    ActionReducerBuilderGet(
      builder,
      getPersonTotalDays,
      "personTotalDays",
      "personTotalDays"
    );

    ActionReducerBuilderGet(
      builder,
      getPersonMonthlyCount,
      "personMonthlyCount",
      "personTotalDays"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectMonthlyCount,
      "projectMonthlyCount",
      "personTotalDays"
    );

    ActionReducerBuilderGet(
      builder,
      getActivityMonthlyCount,
      "activityMonthlyCount",
      "personTotalDays"
    );

    //

    ActionReducerBuilderGet(
      builder,
      getActivityList,
      "activityList",
      "activityList"
    );

    ActionReducerBuilderGet(builder, getTeamList, "teamList", "teamList");

    ActionReducerBuilderGet(
      builder,
      getPlanningProductivity,
      "planningProductivity",
      "planningProductivity"
    );

    ActionReducerBuilderGet(
      builder,
      getCustomerDaysProductivity,
      "customerDaysProductivity",
      "customerDaysProductivity"
    );

    ActionReducerBuilderGet(
      builder,
      getQuarterlyActivitySummary,
      "quarterlyActivitySummary",
      "quarterlyActivitySummary"
    );

    ActionReducerBuilderGet(
      builder,
      getQuarterlyDaysSummary,
      "quarterlyDaysSummary",
      "quarterlyDaysSummary"
    );

    ActionReducerBuilderGet(builder, getDaysList, "daysList", "daysList");

    ActionReducerBuilderGet(
      builder,
      getActivityTypesWithAllowances,
      "activityTypes",
      "activityTypes"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectsForPlanning,
      "projects",
      "projects"
    );

    ActionReducerBuilderGet(
      builder,
      getAcquiredDeviation,
      "deviationResults",
      "deviationResults"
    );

    ActionReducerBuilderGet(
      builder,
      getNextEventStartDate,
      "nextEventStartDate",
      "nextEventStartDate"
    );

    ActionReducerBuilderGet(
      builder,
      getCustomersForPlanning,
      "customers",
      "customers"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectsForActivityStatus,
      "activityStatusProjects",
      "projectActivityStatus"
    );

    ActionReducerBuilderGet(
      builder,
      getDealersForPlanning,
      "dealers",
      "dealers"
    );

    ActionReducerBuilderGet(
      builder,
      getEventActivityTypes,
      "eventActivityTypes",
      "eventActivityTypes"
    );
    ActionReducerBuilderGet(builder, getEventById, "event", "event");

    ActionReducerBuilderDelete(builder, deleteEvent, "event", "event");

    ActionReducerBuilderGet(
      builder,
      getEventsForApproval,
      "eventsToApprove",
      "eventsToApprove"
    );

    ActionReducerBuilderPut(
      builder,
      putEventApproval,
      "eventsToApprove",
      "eventsToApprove"
    );

    ActionReducerBuilderPost(builder, postEvent, "event", "event", false);

    ActionReducerBuilderGet(builder, getToDos, "todos", "event");

    ActionReducerBuilderGet(
      builder,
      getEventsForecasts,
      "eventsForecasts",
      "eventsForecasts"
    );

    ActionReducerBuilderGet(
      builder,
      getCompletedDeviations,
      "completedDeviations",
      "completedDeviations"
    );

    ActionReducerBuilderGet(
      builder,
      getDeviationSections,
      "deviationSection",
      "deviationSection"
    );

    ActionReducerBuilderGet(
      builder,
      getDeviationSubSections,
      "deviationSubSection",
      "deviationSubSection"
    );

    ActionReducerBuilderGet(
      builder,
      getDeviationTopics,
      "deviationTopic",
      "deviationTopic"
    );

    ActionReducerBuilderGet(
      builder,
      getDeviationQuestions,
      "deviationQuestion",
      "deviationQuestion"
    );

    ActionReducerBuilderPut(builder, putEventMilestone, "event", "event");

    ActionReducerBuilderPut(
      builder,
      putEventDeviation,
      "eventResults",
      "eventResults"
    );

    ActionReducerBuilderPut(
      builder,
      putPlanningAllocations,
      "event",
      "planningAllocations"
    );

    ActionReducerBuilderPost(builder, postNote, "event", "eventNote", false);

    ActionReducerBuilderGet(
      builder,
      getPersonAllowancesReport,
      "personAllowanceReport",
      "personAllowanceReport"
    );

    ActionReducerBuilderGet(
      builder,
      getReportCustomers,
      "reportCustomers",
      "reportCustomers"
    );

    ActionReducerBuilderGet(
      builder,
      getReportActivityTypes,
      "reportActivityTypes",
      "reportActivityTypes"
    );

    ActionReducerBuilderGet(
      builder,
      getReportCountries,
      "reportCountries",
      "reportCountries"
    );

    ActionReducerBuilderGet(
      builder,
      getCustomEvent,
      "customEvent",
      "customEvent"
    );

    ActionReducerBuilderPost(
      builder,
      postCustomEvent,
      "customEvent",
      "customEvent",
      true
    );

    ActionReducerBuilderPut(
      builder,
      putCustomEvent,
      "customEvent",
      "customEvent"
    );

    ActionReducerBuilderDelete(
      builder,
      deleteCustomEvent,
      "customEvent",
      "customEvent"
    );

    ActionReducerBuilderPut(builder, putEventToil, "eventToil", "eventToil");

    ActionReducerBuilderDelete(
      builder,
      deleteEventToil,
      "eventToil",
      "eventToil"
    );

    ActionReducerBuilderGet(builder, getEventSearch, "event", "event");
  },
});

export const {
  resetProject,
  resetStatus,
  resetDialogDropdowns,
  resetAllPlanningData,
  clearAllowances,
  clearActivityList,
  clearCalendar,
} = planningSlice.actions;

export interface IPlanningProjectsAsDict extends IAutocompleteOption {
  meta: {
    startDate?: Date;
    endDate?: Date;
    customerId?: number;
    subCustomers?: number[] | undefined;
    tense?: string | null;
    tenseOrder?: number | null;
    hasFinalQuote?: boolean;
    hasSchedule?: boolean;
  };
}

export interface IPlanningActivityTypeAsDict extends IAutocompleteOption {
  meta: {
    requiresDealer?: boolean;
    requiresApproval?: boolean;
    allowHalfDays?: boolean;
    allowance?: number;
    isActive?: boolean;
    isProductive?: boolean;
    isDisabled?: boolean;
  };
}

export interface IPlanningDealerAsDict extends IAutocompleteOption {
  meta: {
    name?: string;
    code?: string;
  };
}

export const planningProjectsAsDict = (
  state: RootState
): IPlanningProjectsAsDict[] =>
  state.planning.projects
    .map((x: Project) => ({
      value: x.id,
      label: x.code + " " + x.title,
      meta: {
        startDate: x.startDate,
        endDate: x.endDate,
        customerId: x.customerId,
        subCustomers: x.projectCustomers?.map((x) => x.customerId),
        tense: x.tense,
        tenseOrder: x.tenseOrder,
        hasSchedule: (x.activitySchedules?.length ?? 0) > 0,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const planningActivityStatusProjectsAsDict = (
  state: RootState
): IPlanningProjectsAsDict[] =>
  state.planning.activityStatusProjects
    .map((x: Project) => ({
      value: x.id,
      label: x.code + " " + x.title,
      meta: {
        startDate: x.startDate,
        endDate: x.endDate,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const planningActivityTypesAsDict = (
  state: RootState
): IPlanningActivityTypeAsDict[] =>
  state.planning.activityTypes
    .map((x: ActivityTypeWithAllowance) => ({
      value: x.activityType.id,
      label: x.activityType.name,
      meta: {
        requiresDealer: x.activityType.requiresDealer,
        requiresApproval: x.activityType.requiresApproval,
        allowHalfDays: x.activityType.allowHalfDay ?? false,
        allowance: x.allowance,
        isActive: x.activityType.isActive,
        isProductive: x.activityType.isProductive,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const planningDealersAsDict = (
  state: RootState
): IPlanningDealerAsDict[] =>
  state.planning.dealers
    .filter((x: Dealer) => x.isActive)
    .map((x: Dealer) => ({
      value: x.id,
      label: x.fullName,
      meta: {
        name: x.name,
        code: x.code,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const planningCustomersAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.customers
    .map((x) => ({
      value: x.id,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));
};

export const eventActivityTypesAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.eventActivityTypes.activityTypes
    ? state.planning.eventActivityTypes.activityTypes
        .map((x) => ({
          value: x.id,
          label: x.name,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label))
    : [];
};

export const monthSelectAsDict = (state: RootState): IAutocompleteOption[] => {
  var arr = new Array(12).fill(0).map((_, i) => {
    return {
      value: i,
      label: new Date(0, i).toLocaleDateString("en", { month: "long" }),
    };
  });
  arr.unshift({ value: 100, label: "Future" });

  return arr;
};

export const planningDeviationSectionAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.deviationSection
    ? state.planning.deviationSection.map((x) => ({
        value: x.sectionId,
        label: x.sectionName,
      }))
    : [];
};

export const planningDeviationSubSectionAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.deviationSubSection
    ? state.planning.deviationSubSection.map((x) => ({
        value: x.subSectionId,
        label: x.subSectionName,
      }))
    : [];
};

export const planningDeviationTopicAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.deviationTopic
    ? state.planning.deviationTopic.map((x) => ({
        value: x.topicId,
        label: x.topicName,
      }))
    : [];
};

export const planningDeviationQuestionAsDict = (
  state: RootState
): IAutocompleteOption[] => {
  return state.planning.deviationQuestion
    ? state.planning.deviationQuestion.map((x) => ({
        value: x.questionId,
        label: x.questionName,
      }))
    : [];
};

export default planningSlice.reducer;
