import {
  BarChart2,
  BookOpen,
  Calendar,
  CheckSquare,
  FileText,
  // Activity,
  // eslint-disable-next-line
  List,
  MapPin,
  MessageSquare,
  Search,
  Users,
  Clipboard
} from "react-feather";
import {
  AllRoles,
  AllRolesExcluding,
  MatchType,
  Role,
} from "../components/Guards";
import { ToDoNumberWrapper } from "../components/sidebar/ToDoNumberWrapper";

const Projects = [
  {
    href: "/projects/",
    icon: BookOpen,
    title: "Projects",
    MatchType: MatchType.Single,
    roles: [Role.Finance, Role.GroupAdmin, Role.Operations, Role.Projects],
  },
];

const Planning = [
  {
    href: "/planning/",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/planning/schedule",
    icon: List,
    title: "Schedule",
  },
  {
    href: "/planning/todo",
    icon: CheckSquare,
    title: "To Do",
    badge: <ToDoNumberWrapper />,
  },
  {
    href: "/planning/eventsearch",
    icon: Search,
    title: "Event Search",
    roles: AllRolesExcluding(Role.Specialist),
    matchType: MatchType.Single,
  },
  {
    href: "/admin/activityapprovals",
    icon: FileText,
    title: "Activity Approvals",
    roles: AllRolesExcluding(Role.Specialist),
    matchType: MatchType.Single,
  },
  {
    href: "/admin/purchaseorders",
    icon: FileText,
    title: "Purchase Orders",
    roles: AllRolesExcluding(Role.Specialist),
    matchType: MatchType.Single,
  },
  {
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/planning/reports/activitylist",
        icon: BarChart2,
        title: "Activity List",
      },
      {
        href: "/planning/reports/teamlist",
        icon: BarChart2,
        title: "Team View",
      },
      {
        href: "/planning/reports/allowancesreport",
        icon: BarChart2,
        title: "Allowances",
      },
      {
        href: "/planning/reports/planningproductivity",
        icon: BarChart2,
        title: "Planning Productivity",
      },
      {
        href: "/planning/reports/customerdaysproductivity",
        icon: BarChart2,
        title: "Customer Days Productivity",
      },
      {
        href: "/planning/reports/quarterlyactivitysummary",
        icon: BarChart2,
        title: "Quarterly Activity Summary",
      },
      {
        href: "/planning/reports/quarterlydayssummary",
        icon: BarChart2,
        title: "Quarterly Days Summary",
      },
      {
        href: "/planning/reports/dayslist",
        icon: BarChart2,
        title: "Days List",
      },
      {
        href: "/planning/reports/staffdays",
        icon: BarChart2,
        title: "Staff Days",
        roles: AllRolesExcluding(Role.Specialist),
        matchType: MatchType.Single,
      },
    ],
  },
];

const ECS = [
  {
    title: "Apps",
    icon: Users,
    href: "/ecs/apps",
  },
  {
    title: "Contacts",
    icon: Users,
    href: "/ecs/contacts",
  },
  {
    title: "About Us",
    icon: Users,
    href: "/ecs/about-us",
  },
  {
    title: "Documentation",
    icon: FileText,
    href: "/ecs/group-policies",
  },
  {
    title: "Templates",
    icon: FileText,
    href: "/ecs/templates",
  },
];

const Finance = [
  {
    title: "Pricing",
    icon: FileText,
    roles: [Role.Finance],
    matchType: MatchType.Single,
    children: [
      {
        href: "/finance/currencies",
        icon: FileText,
        title: "Currencies",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
      {
        href: "/finance/teampricinggroups",
        icon: FileText,
        title: "Team Pricing Groups",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
      {
        href: "/finance/countrypricinggroups",
        icon: FileText,
        title: "Country Pricing Groups",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
    ],
  },
  {
    href: "/finance/invoiceschedule",
    icon: FileText,
    title: "Invoice Schedule",
    roles: [Role.Finance],
    matchType: MatchType.Single,
  },
  {
    title: "Odometer Readings",
    icon: FileText,
    href: "/finance/odometerreadings",
    roles: [Role.Finance],
    matchType: MatchType.Single,
  },
  {
    title: "Expenses",
    icon: FileText,
    roles: [Role.Finance],
    matchType: MatchType.Single,
    children: [
      {
        href: "/finance/expenses/exchangerates",
        icon: FileText,
        title: "Expense Exchange Rates",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
      {
        href: "/finance/expenses/upload",
        icon: FileText,
        title: "Upload Expenses / Mileage",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
      {
        href: "/finance/expenses/edit",
        icon: FileText,
        title: "Edit Expenses",
        roles: [Role.Finance],
        matchType: MatchType.Single,
      },
    ],
  },
  {
    icon: FileText,
    title: "Reports",
    roles: [Role.Finance],
    matchType: MatchType.Single,
    children: [
      {
        href: "/finance/reports/expense",
        title: "Expense Report",
        roles: [Role.Finance],
        matchType: MatchType.Single,
        icon: FileText,
      },
      {
        href: "/finance/reports/analysis",
        title: "Analysis Reports",
        roles: [Role.Finance],
        matchType: MatchType.Single,
        icon: FileText,
      },

    ],
  },
];

const Resourcing = [
  {
    title: "Reports",
    icon: FileText,
    children: [
      {
        href: "/resourcing/persontotaldays",
        icon: BarChart2,
        title: "Peoples Total Days",
        matchType: MatchType.Single,
      },
      {
        href: "/resourcing/personmonthlycount",
        icon: BarChart2,
        title: "Persons event count per month",
        matchType: MatchType.Single,
      },
      {
        href: "/resourcing/projectmonthlycount",
        icon: BarChart2,
        title: "Projects event count per month",
        matchType: MatchType.Single,
      },
      {
        href: "/resourcing/activitymonthlycount",
        icon: BarChart2,
        title: "Activity Type event count per month",
        matchType: MatchType.Single,
      },
    ],
  },
];

const Admin = [
  {
    href: "/core/auditlogs",
    title: "Audit Logs",
    matchType: MatchType.Single,
  },
  {
    href: "/feedback",
    icon: MessageSquare,
    title: "Feedback",
    matchType: MatchType.Single,
  },
  {
    href: "/core/customers",
    icon: FileText,
    title: "Customers",
    matchType: MatchType.Single,
  },
  {
    href: "/core/dealerimports",
    icon: FileText,
    title: "Dealer Import",
    matchType: MatchType.Single,
  },
  {
    href: "/core/dealerlocations",
    icon: FileText,
    title: "Dealer Locations",
    matchType: MatchType.Single,
  },
  {
    href: "/admin/fillunplanneddays",
    icon: FileText,
    title: "Fill Unplanned Days",
    matchType: MatchType.Single,
    roles: [Role.GroupAdmin],
  },
  {
    href: "/admin/nordicExpense",
    title: "Nordic Expenses",
    roles: [Role.Finance],
    MatchType: MatchType.Single,
    icon: FileText,
  },
  {
    matchType: MatchType.Single,
    title: "Projects",
    children: [
      {
        href: "/projects/create",
        icon: FileText,
        title: "Create",
        matchType: MatchType.Single,
      },
      {
        href: "/projects/types",
        icon: FileText,
        title: "Types",
        matchType: MatchType.Single,
      },
      {
        href: "/projects/stages",
        icon: FileText,
        title: "Stages",
        matchType: MatchType.Single,
      },
      {
        href: "/projects/status",
        icon: FileText,
        title: "Status",
        matchType: MatchType.Single,
      },
    ],
  },
];

const Operations = [
  {
    href: "/operations/dealerlocations",
    icon: MapPin,
    title: "Dealer Locations",
    matchType: MatchType.Single,
    roles: [Role.Finance, Role.Operations],
  },
  {
    href: "/operations/deviationstatusoverview",
    icon: Clipboard,
    title: "Deviation Status Overview",
    matchType: MatchType.Single,
    roles: [Role.Operations, Role.Projects]
  }
];

const navItems = [
  {
    matchType: MatchType.Single,
    title: "Projects",
    pages: Projects,
    roles: [Role.Finance, Role.GroupAdmin, Role.Operations, Role.Projects],
  },
  {
    title: "Planning",
    pages: Planning,
    matchType: MatchType.Single,
    roles: AllRoles,
  },
  {
    title: "Finance",
    pages: Finance,
    matchType: MatchType.Single,
    roles: [Role.Finance],
  },
  {
    title: "Resourcing",
    pages: Resourcing,
    matchType: MatchType.Single,
    roles: [Role.Finance],
  },
  {
    matchType: MatchType.Single,
    roles: [Role.Operations, Role.Finance],
    pages: Operations,
    title: "Operations",
  },
  {
    title: "Organisation",
    pages: ECS,
    matchType: MatchType.Single,
    roles: AllRoles,
  },
  {
    title: "Admin",
    pages: Admin,
    matchType: MatchType.Single,
    roles: [Role.GroupAdmin],
  },
];

export default navItems;
